import React from 'react';
import Styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Link } from 'gatsby';

const Section = Styled.div`
  min-height:650px;
  background-color:#FFFCF7;
  text-align:center;
  padding-top:30px; 
  background-image:url('https://media.giphy.com/media/gBIRs5wJEIebu/giphy.gif');
  background-size:cover;
  width:100%;
  padding-bottom:40px; 

`;
const Header = Styled.span`
font-size:35px;
  font-weight:600; 
  font-family:'Harmonia Sans','Nunito Sans',sans-serif;
`;
const HeaderDescreption = Styled.p`
margin-top:-1px;
padding-top:10px;
font-family:'Harmonia Sans','Nunito Sans',sans-serif;
`;
const CardWrapper = Styled(Slider)`
display:flex; 
justify-content:space-evenly;
height:450px;
margin-top:50px;
`;
const SingelCardWrapper = Styled.div`
padding: 20px;    outline: none;

`; 
const Card = Styled.div`
  padding:50px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  height: 380px; 

  text-align:center;
  border:3px solid #9ACA38;
  border-color: ${(props) => props.bgColor} ;
  background-color:#fbfdff;
  font-family:'Harmonia Sans','Nunito Sans',sans-serif;
   :hover{
    background-color:#fff0;transform:scale(1.1);border:none;
  transition:transform.3s;
  }
`;

const FirstImage = Styled.div`
height:100px;
  background-image:url("https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_Day_1.png?v=1600302123");
    background-repeat:no-repeat;
  background-position:center;`;
const SecondImage = Styled.div`
height:100px;
  background-image:url("https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_Day_2.png?v=1600302123");
    background-repeat:no-repeat;
  background-position:center;

`;

const CardImage = Styled.div`
height:100px; 
  background-image:url("${prop=>prop.image}");
    background-repeat:no-repeat;
  background-position:center;
`;
const CardTitle = Styled.span`
  font-size:25px;
  font-weight:600;
  `;
const CardDescreption = Styled.p`
padding-top:20px;
`;

function FastingMimicking() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll:2,
          infinite: true,
        }
      }, 
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
    autoplaySpeed: 2000, 
        } 
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  return (
    <>
      <Section>
        <Header>The ProLon Fasting Mimicking Diet</Header>
        <HeaderDescreption>
          <b>ProLon helps unlock the deepest level of fasting</b>
        </HeaderDescreption>
        <HeaderDescreption>
          The program triggers “autophagy,” your body’s natural way of cleaning
          house.
        </HeaderDescreption>
        <HeaderDescreption>
          Your cells clean and recycle old and damaged parts, resulting in a
          profound benefit: cellular rejuvenation.
        </HeaderDescreption>
        <br />
        <div>
          <Slider {...settings} style={{ width: '100%' }}>
            <SingelCardWrapper>
              <Card bgColor={'#9ACA38'}>
                <FirstImage></FirstImage>
                <CardTitle>
                  Transforming to <br></br>a Fasting State
                </CardTitle>
                <CardDescreption>
                  The body transitions to a fasting state & fat-burning mode,
                  and begins preparation for cellular clean-up.
                </CardDescreption>
              </Card>
            </SingelCardWrapper> 
            <SingelCardWrapper>
              <Card bgColor={'#FD6D0F'}>
                <SecondImage></SecondImage>
                <CardTitle>
                  Fat Burning & <br></br>
                  Ketogenesis
                </CardTitle>
                <CardDescreption>
                  Fat-burning ramps up, contributing to the initiation of
                  ketogenesis (ketone production). By the end of this day
                  (48hrs), ketosis may occur.
                </CardDescreption>
              </Card>
            </SingelCardWrapper>
            <SingelCardWrapper> 
              <Card bgColor={'#FED697'} >
                <CardImage  image="https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_Day_3.png?v=1600302123" ></CardImage>
                <CardTitle>
                  Autophagy <br></br>
                  Clean Up State
                </CardTitle>
                <CardDescreption>
                  Cellular clean-up (autophagy) begins. Fat-burning and ketone
                  production/ utilization continues and increases.
                </CardDescreption>
              </Card>
            </SingelCardWrapper>
            <SingelCardWrapper>
              <Card bgColor={'#4a8bca'}> 
                <CardImage image="https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_Day_4.png?v=1600302123" ></CardImage>
                <CardTitle>
                  Cellular <br></br>
                 Rejuvenation
                </CardTitle>
                <CardDescreption>
                Cellular cleaning/renewal &amp; fat-burning continues. You're in the final stretch! 
                </CardDescreption>
              </Card>
            </SingelCardWrapper>
            <SingelCardWrapper> 
              <Card bgColor={'#c534a3'}> 
                <CardImage image="https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_Day_5.png?v=1600302123" ></CardImage>
                <CardTitle>
                  Renewal & Change <br></br>
                 
                </CardTitle>
                <CardDescreption>
               Fast Completed, cellular renewal continues beyond Day 5. 
                </CardDescreption>
              </Card> 
            </SingelCardWrapper>
          </Slider>
        </div>
      </Section>
    </>
  );
}

export default FastingMimicking;
